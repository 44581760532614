import React, { FC, ReactNode } from 'react';

import { Box, Breakpoint, StackProps } from '@mui/material';
import { isNumber } from 'lodash';

import { SidebarWrapper, Wrapper } from './CenteredWithSidebar.styled';

const DEFAULT_SIDEBAR_WIDTH = 400;
const DEFAULT_CONTENT_WIDTH = 720;

type Props = {
  sidebarWidth?: number | string;
  sidebar?: ReactNode;
  maxWidth?: number | Breakpoint;
} & StackProps;

export const CenteredWithSidebar: FC<Props> = ({
  sidebar,
  sidebarWidth = DEFAULT_SIDEBAR_WIDTH,
  maxWidth = DEFAULT_CONTENT_WIDTH,
  children,
  ...props
}) => (
  <Box overflow="auto" height={{ xs: 1, sm: undefined }}>
    <Wrapper {...props}>
      <SidebarWrapper width={sidebarWidth}>
        <Box>{sidebar}</Box>
      </SidebarWrapper>

      <Box component="main" width="100%" maxWidth={maxWidth}>
        {children}
      </Box>
      <Box flexGrow={1} flexShrink={0} sx={{ maxWidth: isNumber(sidebarWidth) ? sidebarWidth + 32 : undefined }} />
    </Wrapper>
  </Box>
);
