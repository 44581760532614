import React, { FC } from 'react';

import { Box, TableCell, styled } from '@mui/material';
import { motion } from 'framer-motion';

const StyledTableCell = styled(TableCell)({
  minHeight: 0,
  height: 'unset',
  padding: 0,
  overflow: 'hidden',
});

export const ExpandableTableCell: FC = ({ children }) => (
  <StyledTableCell>
    <motion.div
      style={{ overflow: 'hidden' }}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
    >
      <Box px={2} py={1}>
        {children}
      </Box>
    </motion.div>
  </StyledTableCell>
);
