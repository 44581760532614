import { isAfter } from 'date-fns';
import type Keycloak from 'keycloak-js';

export const updateKeycloakToken = async (keycloak: Keycloak) => {
  const { refreshTokenParsed } = keycloak;

  if (!refreshTokenParsed?.exp || isAfter(new Date(), new Date(refreshTokenParsed.exp * 1000))) {
    await keycloak.logout();
    return;
  }
  await keycloak.updateToken(10);
};
