import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  padding: spacing(5, 4, 3),
  width: '100%',

  [breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing(2),
  },
}));

export const SidebarWrapper = styled(Stack)(({ theme: { spacing, breakpoints } }) => ({
  flexShrink: 0,
  marginRight: spacing(4),

  '& > div': {
    position: 'sticky',
    top: spacing(5),
  },

  [breakpoints.down('md')]: {
    marginRight: 0,
    width: '100%',
  },
}));
