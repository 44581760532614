import React, { FC, ReactNode } from 'react';
import { isMobile } from 'react-device-detect';

import { Box, Stack, Typography } from '@mui/material';
import { useHover, useToggleState } from 'common/hooks';
import { ChevronRightIcon } from 'icons';

import { ExpandableBoxContent, ExpandableBoxSummary, ExpandableBoxWrapper } from './ExpandableBox.styled';

type Props = {
  Summary: ReactNode | ((params: { isExpanded: boolean }) => ReactNode);
  initialExpanded?: boolean;
  Description?: ReactNode | ((params: { isExpanded: boolean }) => ReactNode);
  Actions?: ReactNode | ((params: { isExpanded: boolean }) => ReactNode);
  size?: 'small' | 'medium';
  showDivider?: boolean;
};

export const ExpandableBox: FC<Props> = ({
  Summary,
  Description,
  initialExpanded = false,
  Actions,
  size = 'medium',
  showDivider = false,
  children,
  ...props
}) => {
  const [isExpanded, toggleExpanded] = useToggleState(initialExpanded);
  const [ref, isHovered] = useHover();
  const isActionsVisible = isHovered || isMobile;

  return (
    <Stack component="section" gap={2} ref={ref} {...props}>
      <ExpandableBoxWrapper
        disableGutters
        // TODO Requires update of @mui/material@^5.15.18 but doesn't work as expected
        // Removing `ExpandableBoxContent` from the DOM is handled by `isExpanded` state
        // slotProps={{ transition: { unmountOnExit: true } }}
        expanded={isExpanded}
        onChange={toggleExpanded}
        size={size}
      >
        <ExpandableBoxSummary expandIcon={<ChevronRightIcon fontSize="small" />} size={size} isExpanded={isExpanded}>
          <Stack flex={1} minHeight={size === 'small' ? 20 : 24} justifyContent="center">
            <Typography variant="natter-text-sm" fontWeight={600} component="h2">
              {typeof Summary === 'function' ? Summary({ isExpanded }) : Summary}
            </Typography>
            {Description && (
              <Typography variant="natter-text-sm" color="text.secondary">
                {typeof Description === 'function' ? Description({ isExpanded }) : Description}
              </Typography>
            )}
          </Stack>

          {Actions && (
            <Box sx={{ visibility: isActionsVisible ? 'visible' : 'hidden' }}>
              {typeof Actions === 'function' ? Actions({ isExpanded }) : Actions}
            </Box>
          )}
        </ExpandableBoxSummary>

        {isExpanded && (
          <ExpandableBoxContent size={size} showDivider={showDivider}>
            {children}
          </ExpandableBoxContent>
        )}
      </ExpandableBoxWrapper>
    </Stack>
  );
};
