import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type WithSizeProp = {
  size: 'small' | 'medium';
};

export const ExpandableBoxWrapper = styled(Accordion, {
  shouldForwardProp: omitForwardedProps('size'),
})<WithSizeProp>(({ theme: { shape }, size }) => ({
  '&.MuiPaper-root': {
    borderRadius: size === 'small' ? shape.borderRadius : shape.borderRadius * 2,
  },
}));

export const ExpandableBoxSummary = styled(AccordionSummary, {
  shouldForwardProp: omitForwardedProps('size', 'isExpanded'),
})<WithSizeProp & { isExpanded: boolean }>(({ theme: { spacing, typography }, size, isExpanded }) => ({
  padding: size === 'small' ? spacing(2) : spacing(3),
  flexDirection: 'row-reverse',
  minHeight: 'unset',
  gap: spacing(2),

  '& .MuiAccordionSummary-content': {
    ...typography['natter-text-xl'],

    margin: 0,
    gap: spacing(2),
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'inherit',

    '&.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },

  ...(isExpanded && {
    transition: 'padding 0.2s',
    paddingBottom: 0,
  }),
}));

export const ExpandableBoxContent = styled(AccordionDetails, {
  shouldForwardProp: omitForwardedProps('size', 'showDivider'),
})<{ showDivider: boolean } & WithSizeProp>(({ theme: { spacing, palette }, size, showDivider }) => ({
  padding: size === 'small' ? spacing(2) : spacing(3),
  ...(showDivider && {
    borderTop: '1px solid',
    borderColor: palette.divider,
  }),
}));
