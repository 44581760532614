import React, { FC } from 'react';

import { Box, TablePagination, TablePaginationProps } from '@mui/material';
import { SearchBox, SearchBoxProps } from 'common/components/SearchBox';

import { TableSearchBarRoot } from './TableSearchBar.styled';

export type TableSearchBarProps = {
  searchboxProps?: SearchBoxProps;
  paginationProps?: Omit<TablePaginationProps, 'onPageChange'> & {
    onPageChange(page: number): void;
  };
};

export const TableSearchBar: FC<TableSearchBarProps> = ({ paginationProps, searchboxProps, ...props }) => {
  if (!(paginationProps || searchboxProps)) return null;

  return (
    <TableSearchBarRoot {...props}>
      {searchboxProps && (
        <Box>
          <SearchBox {...searchboxProps} />
        </Box>
      )}
      <Box flex={1} />
      {paginationProps && (
        <TablePagination
          {...paginationProps}
          page={paginationProps.page - 1}
          onPageChange={(_, page) => paginationProps.onPageChange(page + 1)}
        />
      )}
    </TableSearchBarRoot>
  );
};
