import { EventWizardStep } from 'pages/EventWizard/constants';

import { FormMode } from '../types';

export const getEventWizardInitialActiveStep = (mode: FormMode) => {
  switch (mode) {
    case 'create':
      return EventWizardStep.EntrySurvey;
    case 'repeat':
      return EventWizardStep.EventDetails;
    case 'edit':
      return EventWizardStep.EventDetails;
    default:
      return EventWizardStep.EntrySurvey;
  }
};
