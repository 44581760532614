import { Stack, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type WrapperProps = {
  disablePadding?: boolean;
};

export const Wrapper = styled(Stack, {
  shouldForwardProp: omitForwardedProps<WrapperProps>('disablePadding'),
})<WrapperProps>(({ theme: { spacing, palette, shape }, disablePadding }) => ({
  flexDirection: 'row',
  gap: spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',

  ...(!disablePadding && {
    padding: spacing(1.5, 2),
    border: `1px solid ${palette.divider}`,
    borderRadius: shape.borderRadius,
  }),

  color: palette.text.primary,
  backgroundColor: palette.common.white,

  userSelect: 'none',
}));
