import React, { FC, MouseEventHandler, ReactNode } from 'react';

import { withPopover } from 'common/hocs';
import { MoreVerticalIcon } from 'icons';

import { IconButton, IconButtonProps } from '../IconButton';

type Props = IconButtonProps & {
  Icon?: ReactNode;
  onClick: MouseEventHandler<HTMLElement>;
  isActive: boolean;
};

const Button: FC<Props> = ({
  variant = 'outlined',
  Icon = <MoreVerticalIcon />,
  color = variant === 'text' ? 'text' : undefined,
  isActive,
  ...props
}) => (
  <IconButton color={color} variant={variant} className={isActive ? 'active' : undefined} {...props}>
    {Icon}
  </IconButton>
);

export const IconButtonWithPopover = withPopover(Button);
