import i18n from 'i18n';

export const DEFAULT_EVENT_DESCRIPTION = i18n.t('event:wizard.defaultDescription');

export const EVENT_MIN_DURATION_IN_MINUTES = 5;

export const EVENT_DEFAULT_DURATION_IN_MINUTES = 60;

export enum EventWizardStep {
  EntrySurvey,
  NattersSetup,
  ExitSurvey,
  EventDetails,
}
