import React, { ComponentType, FC, MouseEvent, MouseEventHandler, useState } from 'react';

import { Box, ClickAwayListener, Popover } from '@mui/material';

type Props = {
  onClick: MouseEventHandler<HTMLElement>;
  isActive: boolean;
};

export const withPopover =
  <P extends object>(WrappedComponent: ComponentType<P & Props>): FC<Omit<P, 'onClick' | 'isActive'>> =>
  ({ children, ...props }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
      <>
        <WrappedComponent {...(props as P)} onClick={handleOpen} isActive={!!anchorEl} />
        <Popover
          sx={{ mt: 1 }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Box p={2}>{children}</Box>
          </ClickAwayListener>
        </Popover>
      </>
    );
  };
