/* istanbul ignore file */
import {
  BrowserTracing,
  init,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  reactRouterV5Instrumentation,
  replayIntegration,
} from '@sentry/react';
import { assert, isFeatureEnabled, isProduction } from 'common/utils';
import { reduxHistory } from 'reduxHistory';

const REPLAY_WHITE_BLACK_LSIT_SEVERITY = ['log', 'info'];

export const initSentry = () => {
  assert(window._env_.REACT_APP_API_BASE_URL);
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [window._env_.REACT_APP_API_BASE_URL, /^\//],
        routingInstrumentation: reactRouterV5Instrumentation(reduxHistory),
      }),
      replayIntegration({
        maskAllText: isProduction(),
        maskAllInputs: isProduction(),
        blockAllMedia: isProduction(),
        /**
         * https://docs.sentry.io/platforms/javascript/session-replay/understanding-sessions/#ignore-certain-errors-for-error-sampling
         * don't record replays for simple logs
         */
        beforeErrorSampling: (event) => (event.level ? !REPLAY_WHITE_BLACK_LSIT_SEVERITY.includes(event.level) : true),
      }),
    ],
    release: process.env.REACT_APP_TAG,
    environment: window._env_.REACT_APP_ENVIRONMENT,
    enabled: isFeatureEnabled('SentryEnabled'),
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 7,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
  });
};
