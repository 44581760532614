import { createAction } from '@reduxjs/toolkit';

import { EventWizardFormSerializedValues } from '../validation';

export const eventWizardActions = {
  initialized: createAction<{ id?: number; isRepeat?: boolean }>('[Event Wizard] initialized'),
  exited: createAction('[Event Wizard] exited'),
  publishButton: {
    clicked: createAction<EventWizardFormSerializedValues>('[Event Wizard] publish button clicked'),
  },
  updateButton: {
    clicked: createAction<EventWizardFormSerializedValues>('[Event Wizard] update button clicked'),
  },
  cancelButton: {
    clicked: createAction('[Event Wizard] cancel button clicked'),
  },
  eventCreated: createAction('[Event Wizard] event created'),
  progressTracker: {
    navigateToStep: createAction<number>('[Event Wizard Progress Tracker] navigate to step'),
    stepValidationCompleted: createAction<{ step: number; isValid: boolean }>(
      '[Event Wizard Progress Tracker] Step validation completed'
    ),
  },
  form: {
    validity: {
      changed: createAction<boolean>('[Event Wizard Form] validity changed'),
    },
    completed: createAction<void>('[Event Wizard Form] completed'),
  },
  details: {
    videoDropzone: {
      fileSelected: createAction('[Event Wizard Details Video Dropzone] file selected'),
      uploadCanceled: createAction('[Event Wizard Details Video Dropzone] upload canceled'),
      uploadCompleted: createAction('[Event Wizard Details Video Dropzone] upload completed'),
      uploadFailed: createAction<string>('[Event Wizard Details Video Dropzone] upload failed'),
    },
  },
  eventCreationModal: {
    closed: createAction('[Event Wizard Event Creation Modal] closed'),
  },
  eventLimitReachedModal: {
    confirmedButton: {
      clicked: createAction('[Event Wizard Event Limit Reached Modal] confirmed button clicked'),
    },
  },
};
