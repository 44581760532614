/* eslint-disable no-console */
import { Breadcrumb, addBreadcrumb, captureException, captureMessage } from '@sentry/react';
import { isFeatureEnabled } from 'common/utils';

const sentryLogger = {
  addBreadcrumb: (message: string, breadcrumb: Breadcrumb = { level: 'info' }) => {
    addBreadcrumb({ ...breadcrumb, message });
  },
  debug: (message: string, extra?: Record<string, unknown>) => {
    try {
      captureMessage(message, {
        level: 'debug',
        extra,
      });
    } catch (error) {
      console.error(error);
    }
  },
  error: (exception: unknown, extra?: Record<string, unknown>) => {
    try {
      captureException(exception, {
        level: 'error',
        extra,
      });
    } catch (error) {
      console.error(error);
    }
  },
  info: (message: string, extra?: Record<string, unknown>) => {
    try {
      captureMessage(message, {
        level: 'info',
        extra,
      });
    } catch (error) {
      console.error(error);
    }
  },
  log: (message: string, extra?: Record<string, unknown>) => {
    try {
      captureMessage(message, {
        level: 'log',
        extra,
      });
    } catch (error) {
      console.error(error);
    }
  },
  warning: (message: string, extra?: Record<string, unknown>) => {
    try {
      captureMessage(message, {
        level: 'warning',
        extra,
      });
    } catch (error) {
      console.error(error);
    }
  },
};

const localLogger = {
  addBreadcrumb: (message: string, breadcrumb?: Breadcrumb) => {
    console.log(message, breadcrumb);
  },
  debug: (message: string, extra?: Record<string, unknown>) => {
    console.debug(message, extra);
  },
  error: (exception: unknown, extra?: Record<string, unknown>) => {
    console.error(exception, extra);
  },
  info: (message: string, extra?: Record<string, unknown>) => {
    console.info(message, extra);
  },
  log: (message: string, extra?: Record<string, unknown>) => {
    console.log(message, extra);
  },
  warning: (message: string, extra?: Record<string, unknown>) => {
    console.warn(message, extra);
  },
};

export const logger = isFeatureEnabled('SentryEnabled') ? sentryLogger : localLogger;
