import React, { ComponentProps, FC } from 'react';

import { Typography } from '@mui/material';

import { Switch, SwitchProps } from '../Switch';
import { Wrapper } from './SwitchBox.styled';

type Props = SwitchProps & ComponentProps<typeof Wrapper>;

export const SwitchBox: FC<Props> = ({ label, name, id = name, sx, disablePadding, ...props }) => (
  <Wrapper sx={sx} disablePadding={disablePadding}>
    <Typography variant="natter-text-sm" component="label" htmlFor={id}>
      {label}
    </Typography>

    <Switch size="small" name={name} id={id} {...props} />
  </Wrapper>
);
