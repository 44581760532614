import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { useBreakpoints } from 'common/hooks';

import { Step, TaskStep } from './components';

type Props = {
  steps: Step[];
  activeStep: number;
  onChange?(index: number, step: Step): void;
  sx?: SxProps<Theme>;
};

export const TaskStepper: FC<Props> = ({ steps, activeStep, onChange, sx }) => {
  const { t } = useTranslation('common', { keyPrefix: 'taskStepper' });
  const { isMobile } = useBreakpoints();

  if (isMobile)
    return (
      <Typography variant="natter-text-sm">
        {t('mobileSteps', { currentStep: activeStep + 1, totalSteps: steps.length })}
      </Typography>
    );

  return (
    <Stack component="aside" gap={2} sx={sx} data-testid="TaskStepper">
      {steps.map((step, index) => (
        <TaskStep
          key={index}
          onClick={typeof onChange === 'function' ? () => onChange(index, step) : undefined}
          isActive={activeStep === index}
          {...step}
        />
      ))}
    </Stack>
  );
};
