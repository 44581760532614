import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { logger } from 'common/services';
import { isAgoraRTCError } from 'devices/utils';
import { BLOCKED_CAMERA_ERROR } from 'event/live/constants';
import { addAppErrorMessage } from 'store/features/alerts';
import { setMutedOnSystemLevel } from 'store/features/user';

import { AGORA_UNEXPECTED_AUDIO_ERROR, AGORA_UNEXPECTED_VIDEO_ERROR, CreateTrackError } from '../utils/errors';
import { eventBroadcastActions } from './eventBroadcast.actions';

export const eventBroadcastListener = createListenerMiddleware();

eventBroadcastListener.startListening({
  matcher: isAnyOf(
    eventBroadcastActions.agora.createAudioTrack.failed,
    eventBroadcastActions.agora.publishAudioTrack.failed
  ),
  effect: ({ payload: { error } }, { dispatch }) => {
    logger.error(error);

    if (!(error instanceof CreateTrackError)) {
      dispatch(addAppErrorMessage(AGORA_UNEXPECTED_AUDIO_ERROR));
    }

    dispatch(setMutedOnSystemLevel(false));
  },
});

eventBroadcastListener.startListening({
  actionCreator: eventBroadcastActions.agora.removeAudioTrack.failed,
  effect: ({ payload: { error } }, { dispatch }) => {
    logger.addBreadcrumb('[Event Broadcast] error while trying to remove audio track', { data: { error } });

    dispatch(addAppErrorMessage('Unexpected error while removing audio track'));
  },
});

eventBroadcastListener.startListening({
  matcher: isAnyOf(
    eventBroadcastActions.agora.createVideoTrack.failed,
    eventBroadcastActions.agora.removeVideoTrack.failed,
    eventBroadcastActions.agora.video.track.stateChanged.failed,
    eventBroadcastActions.agora.publishVideoTrack.failed
  ),
  effect: ({ payload: { error } }, { dispatch }) => {
    logger.error(error);

    if (isAgoraRTCError(error) && error.code === 'NOT_READABLE') {
      dispatch(addAppErrorMessage(`Could not start video source. ${BLOCKED_CAMERA_ERROR}`));
    } else if (!(error instanceof CreateTrackError)) {
      dispatch(addAppErrorMessage(AGORA_UNEXPECTED_VIDEO_ERROR));
    }
  },
});
