import { AnyAction, ThunkDispatch, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { logger } from 'common/services';
import { isError } from 'common/utils';
import { MediaDevice, MediaSettingsState } from 'domain/event';
import { isEqual } from 'lodash';
import { AppDispatch, RootState } from 'store';

import { addAppErrorMessage } from '../alerts';
import { selectMediaDevices } from './selectors';
import { UserSettings } from './types';
import { userSlice } from './userSlice';
import { mapDevicesForOurApp } from './utils/mapDevicesForOurApp';

type ThunkAPI = {
  dispatch: AppDispatch;
  state: RootState;
  extra: unknown;
  rejectWithValue: unknown;
};

const devicesChangedHandler = async (
  _: undefined,
  { dispatch, getState }: { dispatch: ThunkDispatch<RootState, unknown, AnyAction>; getState: () => RootState }
): Promise<void> => {
  try {
    const currentMediaDeviceIds = selectMediaDevices(getState()).map(({ deviceId }) => deviceId);
    const devices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
    logger.addBreadcrumb('[navigator media devices] devices found', {
      data: { devices },
    });
    const mappedDevices = mapDevicesForOurApp(devices);
    const deviceIds = mappedDevices.map(({ deviceId }) => deviceId);

    /**
     * We do this comparison to prevent unnecessary re-renders
     */
    if (!isEqual(currentMediaDeviceIds, deviceIds)) {
      dispatch(userActions.navigator.mediaDevices.devicesFound(mappedDevices));
    }
  } catch (error) {
    logger.error(error);

    if (isError(error)) {
      dispatch(addAppErrorMessage(error.message));
    }
  }
};

export const userActions = {
  navigator: {
    mediaDevices: {
      devicesChanged: createAsyncThunk<void, undefined, ThunkAPI>(
        '[navigator mediaDevices] devices changed',
        devicesChangedHandler
      ),
      pollForDevicesListChange: createAsyncThunk<void, undefined, ThunkAPI>(
        '[navigator mediaDevices] poll for devices list change',
        devicesChangedHandler
      ),
      devicesFound: createAction<MediaDevice[]>('[navigator mediaDevices] devices found'),
    },
  },
  mediaSettings: {
    settingsUpdated: createAction<UserSettings & MediaSettingsState>('[mediaSettings] settings updated'),
  },
};

export const {
  setAudioEnabled,
  setVideoEnabled,
  setMediaSettings,
  setMediaSettingsIsOpen,
  setMutedOnSystemLevel,
  setMediaAccess,
  setDevicesLoaded,
  setVideoDeviceId,
  setAudioInputDeviceId,
  setMicrophoneIssueDetected,
} = userSlice.actions;
